import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { registerUser } from '../services/register';
import { toast } from 'react-toastify';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        coinexchange
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  const [otp, setOtp] = useState(false);
  const [otplessInstance, setInstance] = useState(null);

  useEffect(() => {
    const callback = (userinfo) => {
      const emailMap = userinfo.identities.find(
        (item) => item.identityType === 'EMAIL'
      );
  
      const mobileMap = userinfo.identities.find(
        (item) => item.identityType === 'MOBILE'
      )?.identityValue;
  
      const token = userinfo.token;
  
      const email = emailMap?.identityValue;
  
      const mobile = mobileMap?.identityValue;
  
      const name = emailMap?.name || mobileMap?.name;
  
      console.log(userinfo);
  
      // Implement your custom logic here.
    };

    // Initialize OTPLESS SDK and store it in state
      /* eslint-disable */
    const OTPlessSignin = new OTPless(callback);
    setInstance(OTPlessSignin);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const body = {
      email: data.get('email'),
      password: data.get('password'),
      username: data.get('username'),
      phone: data.get('phone'),
      referralCode: data.get('referralCode')
    };

    console.log('body', body);
    let email = data.get('email');
    
    if (!otp) {
      if (otplessInstance) {
        otplessInstance.initiate({ channel: "EMAIL", email: email });
        setTimeout(() => {
          setOtp(true);
        }, 2000);
      } else {
        toast.error("OTPless instance not initialized");
      }
    } else {
      let otpValue = data.get('otp');
      if (otplessInstance) {
        otplessInstance.verify({
          channel: "EMAIL",
          email: email,
          otp: otpValue,
        }).then((response) => {
          if (response.statusCode === 400) {
            toast.error("Incorrect Otp!");
          } else {
            registerUser(body);
          }
        });
      }
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="username"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  autoFocus
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  size="small"
                  type='email'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone no."
                  name="phone"
                  size="small"
                  type='number'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="referralCode"
                  label="Referral Code"
                  type="referralCode"
                  id="referralCode"
                  autoComplete="referralCode"
                  size="small"
                />
              </Grid>
              {otp && (
                <Grid item xs={12}>
                  <Typography variant='body1' sx={{ my: 2 }}>Otp is sent to your email address. Please verify otp.</Typography>
                  <TextField
                    required
                    fullWidth
                    name="otp"
                    label="OTP"
                    id="otp"
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </div>
  );
}
