import { ContentCopy, Notifications } from '@mui/icons-material'
import { Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { useContext } from 'react';
import { UserContext } from '../../context/userContext';
import { useEffect } from 'react';
import { fetchProfileData } from '../../services/fetchProfileData';
import { useState } from 'react';

const Dashboard = () => {

    const [userData, setUserData] = useState({});
    const user = useContext(UserContext);
  useEffect(()=>{
    async function fetchData(){
      const response =  await fetchProfileData(user.userData.userId)
      setUserData(response);
    }
    fetchData();
  },[user]);

  console.log('userData', userData);
    return (
        <div>
            <Paper sx={{ p: 2, mb: 2 }}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item><Notifications color="primary" /></Grid>
                    <Grid item xs>
                        <Typography variant="h6">Please Allow / Reset Browser Notification</Typography>
                        <Typography variant="body2">
                            If you want to get push notification then you have to allow notification from your browser
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>

            {/* Referral Link */}
            <Typography variant="h6" sx={{ mb: 1 }}>Website Link</Typography>
            <Paper sx={{ p: 2, mb: 2 }}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value= {process.env.REACT_APP_URL}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton color="primary">
                            <ContentCopy />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>

            {/* Cryptocurrency Info */}
            
            <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>Referral Code : {userData.username}</Typography>
            </Paper>
                
            

        </div>
    )
}

export default Dashboard