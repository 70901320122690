import { Typography } from '@mui/material'
import React from 'react'

const Referrals = () => {
  return (
    <div>
        <Typography variant="h6" sx={{ mb: 1 }}>Referrals</Typography>
        </div>
  )
}

export default Referrals