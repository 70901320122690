import { Button, Grid, TextField, Typography, Paper,IconButton } from '@mui/material'
import React from 'react';
import { manualDeposit } from '../../services/depositService';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { ContentCopy, Notifications } from '@mui/icons-material'

const ManualDeposit = ({ userData, reloadFn }) => {


  console.log(userData);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const body = {
      amount: data.get('amount'),
      transactionMode: "manual",
      transactionRef: data.get('transactionRef'),
    };

    await manualDeposit(body);
    // reloadFn();
    console.log('body', body);
  };

  
    const handleCopy = () => {
      const depositAddress = process.env.REACT_APP_DEPOSIT_ADDRESS;
      navigator.clipboard.writeText(depositAddress)
      
    };
  
  return (
    <div>
      <Typography variant="h6" sx={{ mb: 1 }}>Manual Deposit</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <Typography variant="body2" sx={{ py: 2, textAlign: 'center' }}>
                    Kindly please pay on below Address and submit the form with transaction Hash.
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={"/qr.png"} style={{ backgroundColor: "white" }} width={300} height={300} alt="Logo" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                
                <Typography variant="body2" sx={{ py: 2 }}>
                <span style={{ fontWeight: 'bold', color: 'red' }}>USDT (BEP-20) Address: </span> 
                <b><i>{process.env.REACT_APP_DEPOSIT_ADDRESS}</i></b> 
                <IconButton color="primary" onClick={handleCopy}>
                <ContentCopy />
                </IconButton>
                </Typography>


                </div>          
              </Grid>
            </Grid>
          </Paper>
          <Grid component="form" onSubmit={handleSubmit} container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                value={userData?.username || ""}
                margin="normal"
                required
                fullWidth
                size="small"
                id="username"
                label="User name"
                name="username"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={userData?.email || ""}
                margin="normal"
                required
                fullWidth
                size="small"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                id="transactionRef"
                label="Transaction Hash"
                name="transactionRef"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                id="amount"
                label="Amount"
                name="amount"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ManualDeposit