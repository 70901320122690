import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosInstance';
import { TextField, Button, Grid, MenuItem, Typography, Box } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

const KycVerify = ({ userId }) => {
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [documentFile, setDocumentFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [kycStatus, setKycStatus] = useState(null); // Track KYC status

  const documentOptions = [
    { value: 'aadhar', label: 'Aadhar' },
    { value: 'identityCertificate', label: 'Identity Certificate' },
    { value: 'drivingLicense', label: 'Driving License' },
  ];

  // Fetch KYC status when the component mounts
  useEffect(() => {
    const fetchKycStatus = async () => {
      try {
        const response = await axiosInstance.get(`kyc/kyc`);
        const kyc = response.data;
        
        // Assume kyc.status indicates whether the user is verified or not
        setKycStatus(kyc.status); // Assuming status is 'verified', 'pending', etc.
      } catch (error) {
        console.error('Error fetching KYC status:', error);
      }
    };

    fetchKycStatus();
  }, [userId]);

  const handleFileUpload = (event) => {
    setDocumentFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('documentType', documentType);
    formData.append('documentNumber', documentNumber);
    formData.append('documentFile', documentFile);

    try {
      const response = await axiosInstance.post('kyc/kyc', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || "error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // If KYC is verified, show the verified status and hide the form
  if (kycStatus === 'verified') {
    return (
      <div style={{ marginTop: 100 }}>
      <Box sx={{ maxWidth: 500, margin: 'auto', padding: 3 }}>
        <Typography variant="h6" color="success" gutterBottom>
          Your KYC is already verified!
        </Typography>
      </Box>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 100 }}>
      <Box sx={{ maxWidth: 500, margin: 'auto', padding: 3 }}>
        <Typography variant="h5" gutterBottom>
          KYC Document Verification
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Select Document Type"
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                fullWidth
                required
              >
                {documentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Document Number"
                value={documentNumber}
                onChange={(e) => setDocumentNumber(e.target.value)}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                startIcon={<CloudUpload />}
              >
                Upload Document
                <input
                  type="file"
                  hidden
                  accept=".pdf,.jpeg,.jpg,.png"
                  onChange={handleFileUpload}
                />
              </Button>
              {documentFile && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected file: {documentFile.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>

            {message && (
              <Grid item xs={12}>
                <Typography variant="body2" color={message.includes('Error') ? 'error' : 'success'}>
                  {message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default KycVerify;
